
import { Component, Vue } from 'vue-property-decorator';

// Utilities
import { AdminService } from '@/services';

// Types
import { IAdminCorporate } from '@/types/admin.types';
import { CorporateMatchingType } from '@/types/corporates.types';

@Component({
  components: {}
})
export default class AdminCorporates extends Vue {
  allCorporates: IAdminCorporate[] = [];

  headers = [
    { text: 'NAME', value: 'name' },
    { text: 'PRIORITY', value: 'priority' },
    { text: 'COVER IMAGE', value: 'coverImg' },
    { text: 'LOGO URL', value: 'logoUrl' },
    { text: 'MATCHING TYPE', value: 'matchingType' },
    { text: 'Actions', value: 'actions', sortable: false }
  ];

  editedIndex = -1;
  dialog = false;
  logoImageFile = null;
  CoverImageFile = null;
  LogoUploadedImage: any = '';
  CoverUploadedImage: any = '';
  isUpdateCorporate = false;

  defaultData = {
    id: '',
    address1: '',
    address2: '',
    city: '',
    countryCode: '',
    coverImg: '',
    createdAt: '',
    description: '',
    lastSyncAt: '',
    logoUrl: '',
    matchingType: CorporateMatchingType.OFFER,
    name: '',
    phone: '',
    postCode: '',
    priority: 0,
    state: '',
    title: '',
    updatedAt: '',
    websiteUrl: ''
  };

  editedItem: IAdminCorporate = this.defaultData;
  defaultItem: IAdminCorporate = this.defaultData;

  editItem(item: IAdminCorporate): void {
    this.logoImageFile = null;
    this.LogoUploadedImage = null;
    this.CoverImageFile = null;
    this.CoverUploadedImage = null;
    this.editedIndex = this.allCorporates.indexOf(item);
    this.editedItem = Object.assign({}, item);
    this.dialog = true;
  }

  close(): void {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  onFilePickedLogo(e: any): void {
    this.logoImageFile = e.target.files[0];

    const fr = new FileReader();
    fr.readAsDataURL(e.target.files[0]);
    fr.addEventListener('load', () => {
      this.LogoUploadedImage = fr.result;
    });
  }

  onFilePickedCover(e: any): void {
    this.CoverImageFile = e.target.files[0];

    const fr = new FileReader();
    fr.readAsDataURL(e.target.files[0]);
    fr.addEventListener('load', () => {
      this.CoverUploadedImage = fr.result;
    });
  }

  async save(): Promise<void> {
    this.isUpdateCorporate = true;
    let formData = new FormData();
    if (this.logoImageFile) formData.append('logo', this.logoImageFile as any);

    if (this.CoverImageFile) {
      formData.append('coverImage', this.CoverImageFile as any);
    }

    formData.append('priority', this.editedItem.priority as any);
    formData.append('title', this.editedItem.title);
    formData.append('description', this.editedItem.description);
    await AdminService.updateCorporate(formData, this.editedItem.id);
    this.isUpdateCorporate = false;
    this.CoverUploadedImage = '';

    this.close();
    this.getAdminCorporates();
  }

  async getAdminCorporates(): Promise<void> {
    try {
      this.allCorporates = await AdminService.fetchAdminCorporates();
    } catch (e: any) {
      this.$notifyError('Something went wrong');
    }
  }

  async created() {
    this.getAdminCorporates();
  }
}
